import { css } from '@emotion/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { ImageSharp, Maybe, Mdx, MdxFrontmatter } from '../../graphql-types'
import { useTheme } from '@conte-ltd/components-theme'
import { Theme } from '../styles/theme'
import { useMediaQuery } from '@conte-ltd/components-utils'
import { InViewFadeUp } from './in-view-fade'
import { Link } from 'gatsby'

export interface WorksGalleryProps {
  nodes: Array<
    Pick<Mdx, 'id' | 'slug'> & {
      frontmatter?: Maybe<
        Pick<MdxFrontmatter, 'title' | 'subtitle' | 'categories' | 'url'> & {
          thumbnail?: Maybe<{
            childImageSharp?: Maybe<Pick<ImageSharp, 'gatsbyImageData'>>
          }>
        }
      >
    }
  >
}

export function WorksGallery({ nodes }: WorksGalleryProps) {
  const {
    variables: { fontFamily, fontSize, fontWeight, letterSpacing, lineHeight },
    styles: { color, display, gridTemplate, gap, margin },
    breakpoints: bp,
  } = useTheme<Theme>()
  const isMobile = useMediaQuery(bp.md)

  return (
    <div
      css={[
        display.grid,
        gap.all.lg,
        margin.top.xl,
        isMobile ? gridTemplate.columns.none : gridTemplate.columns['2'],
      ]}
    >
      {nodes.map(({ id, slug, frontmatter }) => (
        <InViewFadeUp as={'div'} key={id}>
          <a
            href={
              typeof frontmatter?.url === 'string' ? frontmatter.url : undefined
            }
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            <div
              css={css`
                position: relative;
              `}
            >
              <GatsbyImage
                alt={''}
                image={frontmatter?.thumbnail?.childImageSharp?.gatsbyImageData}
                css={css`
                  filter: brightness(0.8);
                `}
              />

              <div
                css={css`
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                `}
              >
                <p
                  css={css`
                    white-space: nowrap;
                    font-family: ${fontFamily.default};
                    font-size: ${fontSize.lg};
                    font-weight: ${fontWeight.semibold};
                    letter-spacing: ${letterSpacing.wide};
                    color: ${color.white};
                  `}
                  dangerouslySetInnerHTML={{
                    __html: frontmatter?.title ?? '',
                  }}
                />
              </div>
            </div>
            <p
              css={[
                css`
                  font-family: ${fontFamily.jp};
                  font-size: ${fontSize.lg};
                  font-weight: ${fontWeight.semibold};
                  letter-spacing: ${letterSpacing.tighter};
                  line-height: ${lineHeight.normal};
                `,
                margin.top['3xs'],
              ]}
              dangerouslySetInnerHTML={{
                __html: frontmatter?.subtitle ?? '',
              }}
            />
            <p
              css={[
                margin.top['3xs'],
                color.gray,
                css`
                  font-family: ${fontFamily.default};
                  font-size: ${fontSize.xs};
                  font-weight: ${fontWeight.medium};
                  letter-spacing: ${letterSpacing.wide};
                  line-height: ${lineHeight.normal};
                `,
              ]}
            >
              {frontmatter?.categories?.reduce(
                (prev, category, index) =>
                  index === 0 ? category : `${prev} / ${category}`,
                ''
              )}
            </p>
          </a>
        </InViewFadeUp>
      ))}
    </div>
  )
}
